import BasePlugin from '../BasePlugin'

export default class ExportInvestmentExpensesBudgetFromRequest extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    let me = this.context.getModel()
    this.context.getCard().setLoading(true)
    let card = this.context.getCard()
    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/plugins/execute/ExportInvestmentExpensesBudgetsFromRequestQuery?requestId=${recordId}`,
    }).then((response) => {
      let address = `${this.context.$config.api}/etleditor/export/38`
      this.context.$http({
        method: 'post',
        url: address,
        responseType: 'arraybuffer',
        data: { 'ids': response.data, 'parameters': { 'start_year': me.attr_2560_, 'end_year': me.attr_2561_ } }
      }).then(function (response2) {
        card.setLoading(false)
        let blob = new Blob([response2.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }, (response) => { card.setLoading(false) })
    })
  }
}
